<template>
  <div class="page-height" style="padding-bottom: 92px">
    <div class="flex-center data-detail-top">
      <div class="w1200">
        <div class="page-title">{{
            type === '数据服务' ? detail.serviceName + '(' + detail.serviceCode + ')' : detail.catalogName + ' ' + detail.schemaName + ' ' + detail.tabName
          }}
        </div>
        <div class="box-info">{{ type == '数据服务' ? '' : detail.tabRemark }}</div>
      </div>
    </div>
    <div class="flex-center detail-cont">
      <div class="w1200">
        <template v-if="type == '数据服务'">
          <div class="table-box">
            <div class="table-title">requestHead:</div>
            <el-table
                :border="true"
                :data="requestHead"
                :header-cell-style="{background:'#f5f7fa'}"
                :span-method="objectSpanMethod"
                style="width: 100%">
              <el-table-column label="接口参数名称" prop="name"></el-table-column>
              <el-table-column label="参数类型" prop="type"></el-table-column>
              <el-table-column label="参数备注" prop="remark"></el-table-column>
              <el-table-column label="报文样例" width="340">
                <template slot-scope="scope">
                  <pre>
                    <code>
                      {{ ps.requestHead }}
                    </code>
                  </pre>
                </template>
              </el-table-column>
            </el-table>
            <div class="table-title">requestBody:</div>
            <el-table
                :border="true"
                :data="requestBody"
                :header-cell-style="{background:'#f5f7fa'}"
                :span-method="objectSpanMethodBody1"
                style="width: 100%">
              <el-table-column label="接口参数名称" prop="paramCode" width="180"></el-table-column>
              <el-table-column label="参数类型" prop="paramDataType" width="80"></el-table-column>
              <el-table-column label="字符格式" prop="paramVal" width="80"></el-table-column>
              <el-table-column label="是否必填" width="80">
                <template slot-scope="scope">{{scope.row.required === '1' ? '是' : '否'}}</template>
              </el-table-column>
              <el-table-column label="参数备注" prop="paramRemark"></el-table-column>
              <el-table-column label="代码描述" prop="paramName"></el-table-column>
              <el-table-column label="报文样例" width="340">
                <template slot-scope="scope">
                  <pre>
                    <code>
                      {{ ps.requestBody }}
                    </code>
                  </pre>
                </template>
              </el-table-column>
            </el-table>
            <div class="table-title">responseHead:</div>
            <el-table
                :border="true"
                :data="responseHead"
                :header-cell-style="{background:'#f5f7fa'}"
                :span-method="objectSpanMethod"
                style="width: 100%">
              <el-table-column label="接口参数名称" prop="name"></el-table-column>
              <el-table-column label="参数类型" prop="type"></el-table-column>
              <el-table-column label="参数备注" prop="remark"></el-table-column>
              <el-table-column label="报文样例" width="340">
                <template slot-scope="scope">
                   <pre>
                    <code>
                       {{ ps.responseHead }}
                    </code>
                  </pre>
                </template>
              </el-table-column>
            </el-table>
            <div class="table-title">responseBody:</div>
            <el-table
                :border="true"
                :data="responseBody"
                :header-cell-style="{background:'#f5f7fa'}"
                :span-method="objectSpanMethodBody2"
                style="width: 100%">
              <el-table-column label="接口参数名称" prop="paramCode" width="180"></el-table-column>
              <el-table-column label="参数类型" prop="paramDataType" width="80"></el-table-column>
              <el-table-column label="字符格式" prop="paramVal" width="80"></el-table-column>
              <el-table-column label="参数备注" prop="paramRemark"></el-table-column>
              <el-table-column label="代码描述" prop="paramName"></el-table-column>
              <el-table-column label="报文样例" width="340">
                <template slot-scope="scope">
                  <pre>
                    <code>
                       {{ ps.responseBody }}
                    </code>
                  </pre>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
        <template v-else-if="type == '数据资产'">
          <el-table
              :border="true"
              :data="detail.assetDictList"
              :header-cell-style="{background:'#f5f7fa'}"
              style="width: 100%">
            <el-table-column
                label="字段名"
                prop="dictName"
                width="220">
            </el-table-column>
            <el-table-column
                label="字段中文描述"
                prop="dictRemark"
            >
            </el-table-column>
            <el-table-column
                label="字段类型"
                prop="dictType"
                width="140">
            </el-table-column>
            <el-table-column
                label="涉密数据">
              <template slot-scope="scope">
                <div>{{ scope.row.ruleId ? '敏感数据' : '非敏感数据' }}</div>
              </template>
            </el-table-column>
          </el-table>
        </template>
        <div class="detail-bottom">
          <div class="apply-btn">如何获取数据资源？</div>
          <div class="detail-bottom-label">
            <div class="icon-gou iconfont"></div>
            申请账号
          </div>
          <div class="detail-bottom-cont">前往<router-link to="/home#applyAccount" style="color: #2479F0 !important;">开通账号</router-link>填写联系方式，在1-2个工作日内工作人员将和您取得联系及开通账号。
          </div>
          <div class="detail-bottom-label">
            <div class="icon-gou iconfont"></div>
            访问数据仓
          </div>
          <div class="detail-bottom-cont">访问生猪大数据数智云脑，使用工作人员为您开通的账号即可登录。</div>
          <div class="detail-bottom-label">
            <div class="icon-gou iconfont"></div>
            申请数据资源
          </div>
          <div class="detail-bottom-cont">在资产管理-资源管理中，查找需要的数据资源。对需要获取的资源可提出申请，工作人员会在审核通过后放开使用权限。
            目前可申请的服务包括：提供数据查询功能，获取数据资产资源，接口资源。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BapAssetDetail, DspServiceDetail} from "@/assets/js/api";

export default {
  name: "detail",
  data() {
    return {
      requestHead: [
        {name: 'serviceCode', type: 'String', remark: '服务编码', ps: ''},
        {name: 'clientSystemId', type: 'String', remark: '系统编号', ps: ''},
        {name: 'requestTime', type: 'String', remark: '请求时间', ps: ''},
        {name: 'seqNo', type: 'String', remark: '唯一流水', ps: ''},
        {name: 'token', type: 'String', remark: '授权系统token码', ps: ''}
      ],
      responseHead: [
        {name: 'serviceCode', type: 'String', remark: '服务编码', ps: ''},
        {name: 'clientSystemId', type: 'String', remark: '系统编号', ps: ''},
        {name: 'seqNo', type: 'String', remark: '唯一流水', ps: ''},
        {name: 'responseTime', type: 'String', remark: '返回时间', ps: ''},
        {
          name: 'status', type: 'Object', remark: '状态\n' +
              '请求参数错误：A0400\n' +
              '访问未授权：A0301\n' +
              '未知异常：B0001\n' +
              '执行成功：00000\n' +
              '未查得：00099', ps: ''
        },
        {name: 'msg', type: 'Object', remark: '返回中文', ps: ''},
        {name: 'total', type: 'int', remark: '总数', ps: ''}
      ],
      requestBody: [],
      responseBody: [],
      type:'',
      detail:{},
      ps:{
        requestHead:null,
        requestBody:null,
        responseHead:null,
        responseBody:null
      }
    }
  },
  mounted() {
    console.log(this.$route.query)
    this.initData()
  },
  methods: {
    initData() {
      let id = this.$route.query.id
      this.type = this.$route.query.type
      if (this.type == "数据服务") {
        DspServiceDetail({queryId: id}).then(res => {
          this.detail = res
          this.ps.requestHead = res.serviceMap.request.requestHead
          this.ps.requestBody = res.serviceMap.request.requestBody
          this.ps.responseHead = res.serviceMap.response.responseHead
          this.ps.responseBody = res.serviceMap.response.responseBody
          res.serviceParamList.forEach(item => {
            if (item.paramOut == '1') {
              this.responseBody.push(item)
            }
            if (item.paramIn == '1') {
              this.requestBody.push(item)
            }
          })
          if(res.cntStatus == '1'){
            this.ps.requestBody.offsetPage = 0
            this.ps.requestBody.offsetSize = 10
             this.requestBody.push({paramCode:'offsetPage',paramDataType:'int',paramVal:'',required:'',paramRemark:'当前页',paramName:''})
             this.requestBody.push({paramCode:'offsetSize',paramDataType:'int',paramVal:'',required:'',paramRemark:'查询条数',paramName:''})
          }
        })
      } else {
        BapAssetDetail({queryId: id}).then(res => {
          this.detail = res
        })
      }
    },
    objectSpanMethod({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 3) {
        if (rowIndex === 0) {
          return {
            rowspan: 99,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    objectSpanMethodBody1({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 6) {
        if (rowIndex === 0) {
          return {
            rowspan: 99,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    },
    objectSpanMethodBody2({row, column, rowIndex, columnIndex}) {
      if (columnIndex === 5) {
        if (rowIndex === 0) {
          return {
            rowspan: 99,
            colspan: 1
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0
          };
        }
      }
    }
  }
}
</script>

<style scoped>
@import "../../assets/css/dataDirectory/detail.css";
</style>

